import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { MenuItem, Menu } from '@material-ui/core';

export default withStyles((theme) => ({
  list: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    right: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.grey[800],
    boxShadow: `2px 2px 2px ${theme.palette.background.default}`,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transitionDuration={5}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
